import { useParams } from "react-router-dom";
import { isUUIDv4 } from "@/utils";
import NotFound from "@/components/pages/NotFound";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "@/components/common/Loader";
import Http from "@/services/Http/Http.service";
import DropPayment from "./DropPayment";
import FakePayment from "./FakePayment";
import SuccessPayment from "./SuccessPayment";

export default function Payment() {
  const { uuid } = useParams();
  const [payment, setPayment] = useState(false);
  const isUUID4Valid = isUUIDv4(uuid);
  const Component = useRef(Loader);
  const getPayment = useCallback(async () => {
    const url = `/get-payment.php?uuid=${uuid}`;
    const response = await Http.get(url);
    const newPayment = response.data;

    if (response.status === 200 && newPayment?.uuid) {
      setPayment(newPayment);
      if (newPayment?.status === "success") {
        Component.current = SuccessPayment;

        return;
      }
      if (newPayment?.status !== "created") {
        Component.current = NotFound;
        return;
      }
      if (newPayment?.payment_system?.type !== "drop") {
        const redirect = newPayment?.payment_system?.url;
        if (redirect) {
          window.location.href = newPayment?.payment_system?.url;
        } else {
          Component.current = FakePayment;
        }
        return;
      }
      Component.current = DropPayment;
    }
    if (response.status !== 200) {
      Component.current = NotFound;
    }
  }, [uuid]);

  useEffect(() => {
    isUUID4Valid && getPayment();
    // eslint-disable-next-line
  }, []);
  if (!isUUID4Valid) {
    Component.current = NotFound;
  }
  const Render = Component.current;
  return <Render payment={payment} />;
}
