import classes from "./Form.module.css";
import { useState } from "react";
import { getFromLocalStorage } from "@/utils";
import Http from "@/services/Http/Http.service";
const sendForm = (data, setIsSend) => {
  const utms = getFromLocalStorage("utms");
  try {
    Http.post("/send.php", { ...data, utms }).then((resp) => {
      const { response: data } = resp.data;
      console.log(data);
      setIsSend(true);
    });
  } catch (e) {
    console.error(e);
  }
};

const Form = () => {
  const [formData, setFormData] = useState({});
  const [isSend, setIsSend] = useState(false);
  const onSubmitHandler = (e) => {
    e.preventDefault();
    sendForm(formData, setIsSend);
  };
  const onChangeHandler = (e) => {
    setFormData((oldData) => {
      return { ...oldData, [e.target.name]: e.target.value };
    });
  };
  return (
    <form onSubmit={onSubmitHandler} className="mt-4">
      {isSend ? (
        <p>Спасибо за заявку с вами скоро свяжуться</p>
      ) : (
        <>
          <label className={"mb-4 inline-block w-full"}>
            <span className="block font-normal mb-3 text-left">
              Введите ваше имя
            </span>
            <input
              required
              onChange={onChangeHandler}
              type="text"
              name="name"
              className="inline-block border-2 border-primary-500 bg-dark-700 w-full placeholder:text-dark-400 text-white outline-none focus:ring-2 focus:ring-primary-800 p-4 rounded-md"
              placeholder="Например: Иван"
            />
          </label>
          <label>
            <span className="block font-normal mb-3 text-left">
              Введите ваш номер телефона
            </span>
            <input
              required
              onChange={onChangeHandler}
              type="text"
              name="phone"
              className="inline-block border-2 border-primary-500 bg-dark-700 w-full placeholder:text-dark-400 text-white outline-none focus:ring-2 focus:ring-primary-800 p-4 rounded-md"
              placeholder="Например: +7(000)000-00-00"
            />
          </label>
          <button
            type="submit"
            className={`${classes.btn} w-full justify-center mt-5 px-6 py-4`}
          >
            Отправить заявку
          </button>
          <small className="font-normal text-dark-400 mt-5 inline-block">
            Нажимая кнопку "Отправить заявку" вы даете согласие на обработку
            ваших данних
          </small>
        </>
      )}
    </form>
  );
};
export default Form;
