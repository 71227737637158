import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import DynamicButton from "@/components/ui/DynamicButton";
import { useNavigate } from "react-router-dom";
import classes from "./HomeProductsItem.module.css";

const HomeProductsItem = ({ image, title, text, link }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(link);
      }}
      className={classes.HomeProductsItem}
    >
      <img
        src={image}
        alt={title}
        className="rounded-t-lg min-h-[20rem] object-cover object-center"
      />
      <div className="rounded-b-lg h-full bg-dark-800 border border-dark-700 px-5 py-8 text-left flex flex-col justify-between">
        <div>
          <p className="mb-2 text-xl">{title}</p>
          <small className="text-dark-300">{text}</small>
        </div>

        <DynamicButton
          type="link"
          to={link}
          className="flex items-center justify-center gap-x-2 mt-5 px-5 py-3 "
        >
          Подробнее
          <ArrowLongRightIcon className="w-5 h-5" />
        </DynamicButton>
      </div>
    </div>
  );
};

export default HomeProductsItem;
