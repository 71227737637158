const stepsList = [
  {
    title: "Оставьте заявку",
    text: "Оставьте заявку на сайте или в любом удобном Вам мессенджере. Наши специалисты дадут бесплатную, быструю консультацию по вашему вопросу.",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 69" fill="none">
        <path
          d="M61.2362 11.9722C69.2362 19.1722 76.4361 29.8722 75.0361 39.5722C73.5361 49.2722 63.5362 57.9722 52.0362 63.2722C40.6362 68.4722 27.8362 70.2722 18.5362 65.4722C9.23615 60.7722 3.33615 49.4722 1.03615 37.9722C-1.26385 26.3722 0.136151 14.3722 6.53615 7.67222C12.8362 0.872217 24.2362 -0.727783 34.3362 0.272217C44.3362 1.27222 53.2362 4.87222 61.2362 11.9722Z"
          fill="url(#paint0_linear_316_55)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_316_55"
            x1="0"
            y1="68.2692"
            x2="67.9503"
            y2="-6.59103"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="currentColor" className="text-primary-500" />
            <stop
              offset="1"
              stopColor="currentColor"
              className="text-primary-900"
            />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    title: "Получите выгодное предложение в течение 10 минут",
    text: "Менеджер подберет лучшие цены на запчасти и расходные материалы и предоставит вам выгодное предложение в течение 10 минут.",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 81" fill="none">
        <path
          d="M59.3 13.3002C67.5 21.0002 75 30.0002 75.7 39.7002C76.5 49.5002 70.4 59.9002 62.2 68.0002C53.9 76.0002 43.5 81.6002 35 79.7002C26.5 77.7002 20 68.1002 13.4 60.1002C6.89996 52.0002 0.399956 45.5002 0.0999557 38.7002C-0.100044 32.0002 5.89996 24.9002 12.4 17.3002C18.9 9.70024 26 1.50024 34 0.500238C42 -0.499762 51 5.70024 59.3 13.3002Z"
          fill="url(#paint0_linear_316_61)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_316_61"
            x1="0.0950928"
            y1="80.0723"
            x2="79.6677"
            y2="4.49736"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="currentColor" className="text-primary-500" />
            <stop
              offset="1"
              stopColor="currentColor"
              className="text-primary-900"
            />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    title: "Оплачивайте удобным для вас способом",
    text: "Вы оплачиваете стоимость запчастей удобным для вас способом. Мы подтверждаем оплату и заключаем договор поставки агрегата.",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 68" fill="none">
        <path
          d="M61.1999 13.7998C68.8999 21.7998 75.0999 32.6998 73.6999 43.1998C72.3999 53.6998 63.5999 63.7998 53.5999 66.7998C43.5999 69.6998 32.5999 65.3998 22.6999 60.0998C12.8999 54.6998 4.39994 48.2998 1.79994 39.7998C-0.800058 31.1998 2.59994 20.5998 9.09994 12.8998C15.6999 5.19977 25.2999 0.399767 34.7999 0.599767C44.1999 0.799767 53.4999 5.79977 61.1999 13.7998Z"
          fill="url(#paint0_linear_316_57)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_316_57"
            x1="0.922852"
            y1="67.7345"
            x2="67.8311"
            y2="-4.98843"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="currentColor" className="text-primary-500" />
            <stop
              offset="1"
              stopColor="currentColor"
              className="text-primary-900"
            />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    title: "Отправка заказа",
    text: "Согласовываем способ и условия отправки в ваш город и отправляем в течение 24 часов.",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 72" fill="none">
        <path
          d="M62.9 11.8001C70 16.1001 77.7 22.6001 79.8 31.1001C81.9 39.6001 78.3 50.2001 71.2 58.2001C64.2 66.2001 53.6 71.7001 43 71.7001C32.4 71.7001 21.8 66.3001 13.7 58.3001C5.70004 50.2001 0.300037 39.6001 0.100037 28.8001C-0.0999631 18.0001 5.00004 7.00015 13 2.70015C21 -1.59985 32 0.800149 40.7 3.10015C49.4 5.40015 55.9 7.50015 62.9 11.8001Z"
          fill="url(#paint0_linear_316_64)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_316_64"
            x1="0.0943604"
            y1="71.7001"
            x2="70.9816"
            y2="-8.04669"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="currentColor" className="text-primary-500" />
            <stop
              offset="1"
              stopColor="currentColor"
              className="text-primary-900"
            />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
];

export default stepsList;
