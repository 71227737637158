import React, { createContext, useContext, useState } from "react";
import useSessionStorage from "@/hooks/useSessionStorage";
import Http from "@/services/Http/Http.service";
import ErrorList from "@/components/common/ErrorList";

const OrderContext = createContext();

export function useOrderContext() {
  return useContext(OrderContext);
}

export function OrderProvider({ children }) {
  const [order, setOrder] = useState(null);
  const [isVerif, setIsVerif] = useSessionStorage("isVerif", {});
  const [errors, setError] = useState([]);

  const [payment, setPayment] = useState({});

  const payHandler = (
    amount = order?.total_price_with_discount,
    is_upsale = false,
  ) => {
    const url = `/pay-ways.php`;
    Http.get(url).then((response) => {
      if (response.status === 200) {
        setPayment({
          amount,
          is_upsale,
          order: order?.uuid,
          pay_ways: response.data,
        });
      }
      if (response.status !== 200) {
        setError((prev) => [...prev, "Что-то пошло не так"]);
      }
    });
  };
  return (
    <OrderContext.Provider
      value={{
        order: [order, setOrder],
        isVerif: [isVerif, setIsVerif],
        payment: [payment, setPayment],
        payHandler,
        setError,
      }}
    >
      {errors.length > 0 && <ErrorList errors={errors} setError={setError} />}
      {children}
    </OrderContext.Provider>
  );
}
