import FaqItem from "./FaqItem";
import { useRef, useState } from "react";
import faqItemsList from "./faqItemsList";
import Container from "@/components/ui/Container/Container";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const itemRefs = useRef([]);
  const collapseOtherPanels = (openedPanel) => {
    itemRefs.current.forEach((panel) => {
      if (openedPanel !== panel) {
        panel.style.maxHeight = null;
      }
    });
  };
  const togglePanel = (panel) => {
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  };
  const onTitleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    const panel = itemRefs.current[index];
    togglePanel(panel);
    collapseOtherPanels(panel);
  };

  return (
    <section className="py-16" id="faq">
      <Container>
        <h3 className="text-4xl font-bold text-center mb-10">
          Часто задаваемые вопросы
        </h3>
        <div className="rounded-xl" id="accordion-collapse">
          {faqItemsList.map((item, index) => {
            const isActive = index === activeIndex;

            return (
              <FaqItem
                onClickHandler={() => onTitleClick(index)}
                key={index}
                isOpen={isActive}
                {...item}
                isFirst={index === 0}
                itemsRef={itemRefs}
                isLast={index + 1 === faqItemsList.length}
              />
            );
          })}
        </div>
      </Container>
    </section>
  );
};
export default Faq;
