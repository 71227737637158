import {
  AdjustmentsHorizontalIcon,
  BeakerIcon,
  ExclamationCircleIcon,
  BoltIcon,
  ShieldExclamationIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";

const arr = [
  {
    title: "Модификация.",
    text: "Перед покупкой б/у двигателя уточните модель машины и мотора, комплектацию и год выпуска. Контрактные моторы имеют разные характеристики. Зарубежные производители моторов регулярно вносят в них изменения.",
    icon: <AdjustmentsHorizontalIcon className={"w-6 h-6"} />,
  },
  {
    title: "Маслозаливная горловина.",
    text: "Это первое, что нужно осмотреть при покупке б/у двигателя. Если она чрезмерно чистая, практически как новая, значит, ее промывали.",
    icon: <Cog6ToothIcon className={"w-6 h-6"} />,
  },
  {
    title:
      "Ржавчина (обычно можно увидеть на кожухах выпускных коллекторов и на болтах, гайках).",
    text: "Подтеки масла, грязь, пыль — все это можно убрать с поверхности контрактного двигателя. От ржавчины избавиться невозможно.",
    icon: <ExclamationCircleIcon className={"w-6 h-6"} />,
  },
  {
    title: "Дроссель.",
    text: "У исправного контрактного мотора внутри будет чисто. Вентиляция картера должна быть исправной. Важно, чтобы отсутствовал масляный нагар.",
    icon: <BoltIcon className={"w-6 h-6"} />,
  },
  {
    title: "Трещины, подтеки – их быть не должно.",
    text: "При покупке двигателя б/у тщательно осмотрите его.",
    icon: <ShieldExclamationIcon className={"w-6 h-6"} />,
  },
  {
    title: "Окислы.",
    text: "На контрактном двигателе их быть не должно. Иначе это свидетельствует о продолжительном хранении агрегата на сыром складе или на улице.",
    icon: <BeakerIcon className={"w-6 h-6"} />,
  },
];

export default arr;
