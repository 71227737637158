const faqItemsList = [
  {
    title:
      "Зачем мне обращаться в вашу компанию, если я могу сделать все сам и это, нраверное, выйдет дешевле?",
    text:
      "Конечно, если вы знаете у кого можно купить хороший мотор, как его проверить и точно уверены, " +
      "что вам его установят квалифицированные специалисты, то можно попробовать самим. " +
      "Но если вы не хотите ввязываться в эту рутину, бережете свои нервы и время, готовы заплатить за то, " +
      "что за вас все сделают компетентные люди, тогда вы можете отдать решения данного вопроса в руки профессионалов.",
  },
  {
    title: "Что лучше сделать: капитальный ремонт ДВС или купить БУ?",
    text:
      "Все зависит от того, кто вам делает ремонт и какого качества предлагают двигатель. " +
      "Первое, что надо уяснить — желательно не покупать ремонтные агрегаты. " +
      "Чаще всего компании выкупают мертвые моторы, подкидывают туда дешевые запчасти и выставляют на продажу. " +
      "Такие двигатели долго не ходят. В случае, если мотор просто подъедает масло, нет смысла покупать другой, " +
      "его лучше отремонтировать. Если мотор старый, то велика вероятность, " +
      "что вы купите товар не очень хорошего качества. Если вы все же решите делать ремонт своего двигателя, " +
      "то должны быть точно уверены в той компании, которая будет его делать. " +
      "В других случаях — лучше просто купить БУ мотор.",
  },
  {
    title: "На что именно дают гарантию поставщики при покупке мотора?",
    text:
      "Давайте сразу сделаю оговорку, что поставщики как таковую гарантию на двс не дают. " +
      "Гарантию дают производители автомобилей на новый мотор, и она заканчивается после гарантийного периода. " +
      "Поставщики дают время на проверку. " +
      "Многие полагают, что раз вам дали гарантию, то она распространяется на все что угодно: " +
      "подтекает двс, сломана клапанная крышка, разбит поддон, не работает форсунка и т.д. " +
      "На эти вещи никто гарантию не дает! Вы покупаете БУ товар, отсюда и все вытекающие минусы. " +
      "Например для того, чтобы дать гарантию на навесное оборудование, его необходимо продиагностировать, " +
      "т.к. изначально никто его не проверял. " +
      "Поэтому навесное, если и идет в комплекте с мотором, то это можно сказать бонус. " +
      "Хотите гарантию на навеску, покупайте ее отдельно. " +
      "Вы тогда поймете, что разница в цене будет ощутима. " +
      "На все расходные запчасти типа ГРМ, сальники и т.д. гарантия также не распространяется. " +
      "Гарантия идет на дефекты поршневой группы двигателя (кривошипный механизм, поршневая группа, ГБЦ), " +
      "при которых невозможна правильная работа двигателя, " +
      "что определяется компрессией в цилиндрах двигателя и давлением масла.",
  },
  {
    title: "Можете поэтапно расписать процесс работы с вашей компанией?",
    text:
      "Конечно, вы оставляете заявку на сайте и с Вами связывается наш менеджер по телефону или в мессенджерах. " +
      "Вы предоставляете данные по вашему автомобилю и он начинает искать подходящий вариант для Вас. " +
      "Если нужный ДВС есть в наличии, то мы заключаем с вами договор купли-продажи и отправляем его вам. " +
      "Если ДВС нет в наличии, мы ищем подходящий вариант для Вас у наших поставщиков, " +
      "заключаем с вами договор после чего занимаеся растоможкой и доставкой двигателя. " +
      "Обычно все юридические процедуры и доставка занимают до 21 рабочего дня. " +
      "На каждом этапе с Вами заключается договор для обеспечения гарантий выполнения обязательств.",
  },
];
export default faqItemsList;
