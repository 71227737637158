import Promo from "@/components/common/Promo";
import Form from "@/components/common/Form";

const CallToAction = ({ text }) => {
  return (
    <div className="mt-10 rounded-lg bg-dark-800 border border-dark-700 px-5 py-8 font-bold">
      <Promo text={text} />
      <>
        <Form />
      </>
    </div>
  );
};
export default CallToAction;
