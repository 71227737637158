import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import classes from "./NavBar.module.css";
import { Link, NavLink } from "react-router-dom";
import { navigation } from "@/router";
import { useDataContext } from "@/context/DataContext";

const setIsActive = ({ isActive }) => {
  return `${classes.navItem} ${isActive ? classes.active : ""}`;
};

const NavBar = () => {
  const { data } = useDataContext();
  return (
    <Disclosure
      as="nav"
      className={`bg-dark-800 w-full border-b border-dark-700 fixed z-[999991]`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto container px-4 lg:px-6">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-dark-400 hover:bg-dark-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <Link to={"/"} className="font-bold">
                {data.brand}
              </Link>
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => {
                      return (
                        <NavLink
                          key={item.href}
                          className={setIsActive}
                          to={item.href}
                        >
                          {item.name}
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*classNames( , // "block rounded-md px-3 py-2 text-base font-medium", )*/}
          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => {
                return (
                  <NavLink
                    onClick={({ close }) => close()}
                    key={item.href}
                    className={({ isActive }) => {
                      return (
                        setIsActive({ isActive }) +
                        " block rounded-md px-3 py-2 text-base font-medium"
                      );
                    }}
                    to={item.href}
                  >
                    {item.name}
                  </NavLink>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;
