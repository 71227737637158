import Container from "@/components/ui/Container/Container";
import classes from "./Order.module.css";
import { useDataContext } from "@/context/DataContext";
import { useState } from "react";
import MaskedInput from "react-text-mask/dist/reactTextMask";
import { CheckIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { isUUIDv4 } from "@/utils";
import Card from "@/components/ui/Card";

const isValidIcon = (isValid) => {
  return isValid ? (
    <span className="p-1 bg-green-200 rounded-full inline-block text-green-700 ">
      <CheckIcon className="w-4 h-4" />
    </span>
  ) : (
    <span className="p-1 bg-rose-200 rounded-full inline-block  text-rose-700 ">
      <XMarkIcon className="w-4 h-4" />
    </span>
  );
};
export default function Order() {
  const { data } = useDataContext();
  const [isValid, setIsValid] = useState(null);
  const [UUID4, setUUID4] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const inputUUID = event.target.value;
    const isValidUUID4 = isUUIDv4(inputUUID);
    setIsValid(isValidUUID4);

    if (isValidUUID4) {
      setUUID4(inputUUID);
    }
  };
  return (
    <Container>
      <section
        style={{
          minHeight: "calc(100vh - 4rem)",
        }}
        className="flex justify-center items-center"
      >
        <div className="w-full md:w-1/2 lg:w-1/3">
          <h1 className="w-full text-center mb-5 text-2xl font-bold">
            {data.brand}
          </h1>
          <Card
            as="form"
            onSubmit={(e) => {
              e.preventDefault();
              navigate(UUID4);
            }}
            className="px-5 py-8"
          >
            <legend className="mb-4 text-lg">Отследите свой заказ</legend>
            <small className="mb-2 block">Введите ID заказа</small>
            <div className="relative">
              <MaskedInput
                mask={[
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  "-",
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  "-",
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  "-",
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  "-",
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                  /[0-9a-fA-F]/,
                ]}
                guide={false}
                onChange={handleInputChange}
                className={classes.formInput}
                autoComplete="off"
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              />
              {isValid !== null && (
                <span className="absolute right-3 block h-6 top-1/2 -translate-y-1/2">
                  {isValidIcon(isValid)}
                </span>
              )}
            </div>
            <button
              disabled={!isValid}
              type="submit"
              className={`${classes.btn} w-full justify-center mt-5 px-6 py-4 disabled:opacity-50`}
            >
              Проверить заказ
            </button>
          </Card>
        </div>
      </section>
    </Container>
  );
}
