import { Link, useNavigate } from "react-router-dom";
import {
  ArrowLongLeftIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

const NotFound = () => {
  const history = useNavigate();
  const goBack = () => {
    history(-1);
  };
  return (
    <section className="">
      <div className="container flex items-center min-h-screen p-6 mx-auto">
        <div className="flex flex-col items-center max-w-sm mx-auto text-center">
          <p className="p-3 text-sm font-medium text-primary-50 rounded-full bg-primary-500 shadow-lg shadow-primary-500/70">
            <ExclamationCircleIcon className="w-6 h-6" />
          </p>
          <p className="mt-3 text-6xl font-semibold">404</p>
          <h1 className="mt-3 text-2xl font-semibold md:text-3xl">
            Страница не найдена
          </h1>
          <p className="mt-4 text-gray-300 ">
            Страница, которую вы ищете, не существует. Вот несколько полезных
            ссылок:
          </p>

          <div className="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
            <button
              onClick={goBack}
              className="flex items-center justify-center w-1/2 px-5 py-2 text-dark-50 transition-colors duration-500 bg-dark-600/20 rounded-lg gap-x-2 sm:w-auto  hover:bg-dark-800 "
            >
              <ArrowLongLeftIcon className="w-5 h-5 rtl:rotate-180" />
              <span>Назад</span>
            </button>

            <Link
              className="w-1/2 shrink-0 sm:w-auto tracking-wide transition-colors duration-500 shadow-lg shadow-primary-500/50 rounded-lg text-white bg-primary-500 hover:bg-primary-600 px-5 py-2"
              to="/"
            >
              На главную
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
export default NotFound;
