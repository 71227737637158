import { formattedArray } from "@/utils";

const obj = [
  {
    uid: 333228730281,
    title: "Двигатель Хендай Гранд Старекс D4CB",
    sku: "",
    text: "2,5 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "75000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6463-3537-4033-b330-373831366462\\/____D4CB_25____75000.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1001500,
    url: "http://reby.ru/products/tproduct/522893231-333228730281-dvigatel-hendai-grand-stareks-d4cb",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "KAH0RPKEkB6a0aMRj0Ld",
    editions: [
      {
        uid: 333228730281,
        price: "75000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6463-3537-4033-b330-373831366462/____D4CB_25____75000.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 540650580431,
    title: "Двигатель Хендай Старекс D4CB",
    sku: "",
    text: "2,5 л.",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "75000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3165-3130-4061-b531-363634373165\\/___D4CB_25___75000_.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1001600,
    url: "http://reby.ru/products/tproduct/522893231-540650580431-dvigatel-hendai-stareks-d4cb",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "F7huYbilhYZ7JRERbtgY",
    editions: [
      {
        uid: 540650580431,
        price: "75000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3165-3130-4061-b531-363634373165/___D4CB_25___75000_.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 908704113731,
    title: "Двигатель Мерседес Спринтер",
    sku: "",
    text: "2,2 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "130000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3262-6439-4961-b733-623461313737\\/___22____130000_.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1003200,
    url: "http://reby.ru/products/tproduct/522893231-908704113731-dvigatel-mersedes-sprinter",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "jhpxe7S7sx8CgTg8Xprz",
    editions: [
      {
        uid: 908704113731,
        price: "130000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3262-6439-4961-b733-623461313737/___22____130000_.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 632263314621,
    title: "Двигатель Пежо Боксер 4H03",
    sku: "",
    text: "2,2 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "89000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6237-6137-4135-a336-326536383732\\/___4H03_22____89000_.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1003300,
    url: "http://reby.ru/products/tproduct/522893231-632263314621-dvigatel-pezho-bokser-4h03",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "Idjn0dYtdXXbi5UD9YUn",
    editions: [
      {
        uid: 632263314621,
        price: "89000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6237-6137-4135-a336-326536383732/___4H03_22____89000_.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 880041031651,
    title: "Двигатель Рено Мастер",
    sku: "",
    text: "2,5 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "77000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3961-3065-4666-b565-626531333061\\/___25____77000_.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1003400,
    url: "http://reby.ru/products/tproduct/522893231-880041031651-dvigatel-reno-master",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "zyOKjO5uAHNCk7vOSJaf",
    editions: [
      {
        uid: 880041031651,
        price: "77000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3961-3065-4666-b565-626531333061/___25____77000_.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 366892103421,
    title: "Двигатель Рено Мастер G9U 754",
    sku: "",
    text: "2,5 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "80000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3939-3036-4535-a336-373563343366\\/___G9U_754_25____800.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1003500,
    url: "http://reby.ru/products/tproduct/522893231-366892103421-dvigatel-reno-master-g9u-754",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "TYUX5RNj8HXODMdGI7dY",
    editions: [
      {
        uid: 366892103421,
        price: "80000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3939-3036-4535-a336-373563343366/___G9U_754_25____800.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 400492101031,
    title: "Двигатель Рено Мастер M9T 676",
    sku: "",
    text: "2,3 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "60000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3036-6633-4566-b736-623161306532\\/___M9T_676_23____600.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1003600,
    url: "http://reby.ru/products/tproduct/522893231-400492101031-dvigatel-reno-master-m9t-676",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "jdKhzd8IR2mcGY3Flp2J",
    editions: [
      {
        uid: 400492101031,
        price: "60000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3036-6633-4566-b736-623161306532/___M9T_676_23____600.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 436985175041,
    title: "Двигатель Рено Мастер M9T704",
    sku: "",
    text: "2,3 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "103000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6661-6162-4737-b566-653335323963\\/___M9T704_23____1030.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1003700,
    url: "http://reby.ru/products/tproduct/522893231-436985175041-dvigatel-reno-master-m9t704",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "onSjyacr195sGxgOyYpE",
    editions: [
      {
        uid: 436985175041,
        price: "103000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6661-6162-4737-b566-653335323963/___M9T704_23____1030.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 324238503831,
    title: "Двигатель Ситроен Джампер 4H03 10TRJ5",
    sku: "",
    text: "2,2 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "88000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6565-3338-4432-b531-303161396337\\/___4H03_10TRJ5_22___.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1003800,
    url: "http://reby.ru/products/tproduct/522893231-324238503831-dvigatel-sitroen-dzhamper-4h03-10trj5",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "4rJsev3rJ0bZ5TipGDys",
    editions: [
      {
        uid: 324238503831,
        price: "88000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6565-3338-4432-b531-303161396337/___4H03_10TRJ5_22___.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 409556956221,
    title: "Двигатель Ситроен Джампер PUMA",
    sku: "",
    text: "2,2 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "88000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6132-6265-4161-a338-386538636438\\/___PUMA_22____88000_.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1003900,
    url: "http://reby.ru/products/tproduct/522893231-409556956221-dvigatel-sitroen-dzhamper-puma",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "jDmHJmtOPXB0VJuj6gyp",
    editions: [
      {
        uid: 409556956221,
        price: "88000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6132-6265-4161-a338-386538636438/___PUMA_22____88000_.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 961503687091,
    title: "Двигатель Пежо Боксер",
    sku: "",
    text: "2,2 л. Евро 5",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "103000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3430-3735-4665-b564-626665353837\\/___20____45000_.PNG"},{"img":"https:\\/\\/static.tildacdn.com\\/tild3763-6135-4862-b830-393130626332\\/___22___5__103000_.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1004600,
    url: "http://reby.ru/products/tproduct/522893231-961503687091-dvigatel-pezho-bokser",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "sD64iR5GFojj4pXnvhda",
    editions: [
      {
        uid: 961503687091,
        price: "103000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3430-3735-4665-b564-626665353837/___20____45000_.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 402750781941,
    title: "Двигатель Фиат Дукато",
    sku: "",
    text: "2.3 F1AG Дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "142000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6239-3537-4664-a237-663437626338\\/___20____45000_.PNG"},{"img":"https:\\/\\/static.tildacdn.com\\/tild3935-6164-4335-b261-336436306136\\/___23_____142000_.PNG"},{"img":"https:\\/\\/static.tildacdn.com\\/tild6632-3033-4564-b531-373961346634\\/___23_F1AG___142000_.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1004700,
    url: "http://reby.ru/products/tproduct/522893231-402750781941-dvigatel-fiat-dukato",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "dKP92rHRp3djdFB8YEGf",
    editions: [
      {
        uid: 402750781941,
        price: "142000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6239-3537-4664-a237-663437626338/___20____45000_.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 706140756821,
    title: "Двигатель Фиат Дукато 8140.43S",
    sku: "",
    text: "2,8 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "114000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3730-6634-4630-b932-366262356564\\/___814043S_28____114.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1004800,
    url: "http://reby.ru/products/tproduct/522893231-706140756821-dvigatel-fiat-dukato-814043s",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "A7dEd2MLlao9ve2vd7Om",
    editions: [
      {
        uid: 706140756821,
        price: "114000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3730-6634-4630-b932-366262356564/___814043S_28____114.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 892794358851,
    title: "Двигатель Мерседес Спринтер 642.992",
    sku: "",
    text: "3,0 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "148000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3737-3431-4831-a335-343166313835\\/___642992_30____1480.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1000700,
    url: "http://reby.ru/products/tproduct/522893231-892794358851-dvigatel-mersedes-sprinter-642992",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "ic3VgezSBPDf1nvFGhKc",
    editions: [
      {
        uid: 892794358851,
        price: "148000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3737-3431-4831-a335-343166313835/___642992_30____1480.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 536566906181,
    title: "Двигатель Мерседес Спринтер 646.984",
    sku: "",
    text: "2,2 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "95000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6535-6332-4163-b838-643266323632\\/___646984_22____9500.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1000800,
    url: "http://reby.ru/products/tproduct/522893231-536566906181-dvigatel-mersedes-sprinter-646984",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "ECgPcnzC4sIrvXy2lksx",
    editions: [
      {
        uid: 536566906181,
        price: "95000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6535-6332-4163-b838-643266323632/___646984_22____9500.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 719107177801,
    title: "Двигатель Мерседес Спринтер OM611",
    sku: "",
    text: "2,2 л. дизель",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "95000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3537-3239-4432-a233-373563633532\\/___OM611_22____95000.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1000900,
    url: "http://reby.ru/products/tproduct/522893231-719107177801-dvigatel-mersedes-sprinter-om611",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "tj9OIgmrA25sM0mXvc1h",
    editions: [
      {
        uid: 719107177801,
        price: "95000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3537-3239-4432-a233-373563633532/___OM611_22____95000.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 185811771881,
    title: "Двигатель Мерседес Спринтер 642.896",
    sku: "",
    text: "Двигатель Мерседес Спринтер 642.896",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "160000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6432-3838-4666-b039-653532346461\\/___642896___160000_.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1000600,
    url: "http://reby.ru/products/tproduct/522893231-185811771881-dvigatel-mersedes-sprinter-642896",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    brand: "Mеrсеdеs",
    externalid: "MZgr9VA5HzhhTPYXgDG7",
    editions: [
      {
        uid: 185811771881,
        price: "160000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6432-3838-4666-b039-653532346461/___642896___160000_.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 163218851291,
    title: "Двигатель MERCEDES-BENZ 906",
    sku: "",
    text: "На нашем сайте вы можете купить двигатель MERCEDES-BENZ 906 в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "650000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3533-6232-4534-a338-366131666638\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1009800,
    url: "http://reby.ru/products/tproduct/522893231-163218851291-dvigatel-mercedes-benz-906",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "rLO7xKdjUGrhMk4bTs0n",
    editions: [
      {
        uid: 163218851291,
        price: "650000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3533-6232-4534-a338-366131666638/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 348540685271,
    title: "Кабина в сборе DAF XF 105",
    sku: "",
    text: "На нашем сайте вы можете купить кабину в сборе DAF XF 105 в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "510000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3730-6139-4366-b665-303430343038\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1009900,
    url: "http://reby.ru/products/tproduct/522893231-348540685271-kabina-v-sbore-daf-xf-105",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "6Y9crcOHzsGtMY2VnJdR",
    editions: [
      {
        uid: 348540685271,
        price: "510000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3730-6139-4366-b665-303430343038/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 298614203731,
    title: "Кабина каркас Mercedes-Benz Actros MP4",
    sku: "",
    text: "На нашем сайте вы можете купить каркас кабины Mercedes-Benz Actros MP4 в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "90000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3534-6561-4438-a666-656566313165\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1010000,
    url: "http://reby.ru/products/tproduct/522893231-298614203731-kabina-karkas-mercedes-benz-actros-mp4",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "hGVp4s1k5GfAeVmZPypH",
    editions: [
      {
        uid: 298614203731,
        price: "90000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3534-6561-4438-a666-656566313165/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 517798892481,
    title: "Двигатель Deutz BF6L913",
    sku: "",
    text: "На нашем сайте вы можете купить двигатель Deutz BF6L913 в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "420000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3130-3739-4235-b938-323030353033\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1010100,
    url: "http://reby.ru/products/tproduct/522893231-517798892481-dvigatel-deutz-bf6l913",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "r7L9p4eAAJELcY9fpmhI",
    editions: [
      {
        uid: 517798892481,
        price: "420000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3130-3739-4235-b938-323030353033/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 639298328931,
    title: "Двигатель Liebherr D904T",
    sku: "",
    text: "На нашем сайте вы можете купить двигатель Liebherr D904T в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "1200000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6236-3833-4633-b132-626231376236\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1010200,
    url: "http://reby.ru/products/tproduct/522893231-639298328931-dvigatel-liebherr-d904t",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "PT2T8CIKPulUgk1NAm6C",
    editions: [
      {
        uid: 639298328931,
        price: "1200000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6236-3833-4633-b132-626231376236/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 257855741071,
    title: "Двигатель DAF 615 (DF615)",
    sku: "",
    text: "На нашем сайте вы можете купить DAF 615 (DF615) двигатель комплектный по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "380000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/stor6130-6337-4637-a163-653133303366\\/60081908.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1011800,
    url: "http://reby.ru/products/tproduct/522893231-257855741071-dvigatel-daf-615-df615",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "7Jj9DZspvHFRPU65rldo",
    editions: [
      {
        uid: 257855741071,
        price: "380000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/stor6130-6337-4637-a163-653133303366/60081908.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 100521750941,
    title: "Двигатель VOLVO D16G",
    sku: "",
    text: "На нашем сайте вы можете купить VOLVO D16G двигатель комплектный в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "430000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/stor6537-6635-4638-a164-303030323838\\/97496205.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1011900,
    url: "http://reby.ru/products/tproduct/522893231-100521750941-dvigatel-volvo-d16g",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "ZeLipvAbxxzp4vICxVe0",
    editions: [
      {
        uid: 100521750941,
        price: "430000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/stor6537-6635-4638-a164-303030323838/97496205.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 851854146981,
    title: "Двигатель MAN D2565M",
    sku: "",
    text: "На нашем сайте вы можете купить MAN D2565M двигатель комплектный по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "165000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/stor6263-3465-4235-b830-666534333363\\/15791340.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1012000,
    url: "http://reby.ru/products/tproduct/522893231-851854146981-dvigatel-man-d2565m",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "xnuKeJx1mrOBvaSGl5pV",
    editions: [
      {
        uid: 851854146981,
        price: "165000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/stor6263-3465-4235-b830-666534333363/15791340.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 413210078481,
    title: "Двигатель IVECO CURSOR 10 F3AE0681",
    sku: "",
    text: "На нашем сайте вы можете купить IVECO CURSOR 10 F3AE0681 двигатель комплектный&nbsp; по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "310000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/stor6432-3263-4834-b761-646334363561\\/28343036.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1012100,
    url: "http://reby.ru/products/tproduct/522893231-413210078481-dvigatel-iveco-cursor-10-f3ae0681",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "TnCAbprO9ltTcNaBIBte",
    editions: [
      {
        uid: 413210078481,
        price: "310000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/stor6432-3263-4834-b761-646334363561/28343036.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 792890197241,
    title: "Двигатель Scania DC 11 02",
    sku: "",
    text: "На нашем сайте вы можете купить Scania DC 11 02 двигатель комплектный по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "390000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/stor6130-3031-4530-a436-313337373036\\/21961722.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1012200,
    url: "http://reby.ru/products/tproduct/522893231-792890197241-dvigatel-scania-dc-11-02",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "6IH9g310MRrs6hpvCIKz",
    editions: [
      {
        uid: 792890197241,
        price: "390000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/stor6130-3031-4530-a436-313337373036/21961722.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 540218462851,
    title: "Двигатель MAN D2066LF42 EURO5",
    sku: "",
    text: "На нашем сайте вы можете купить MAN D2066LF42 EURO5 двигатель комплектный по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "350000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/stor3930-6265-4935-b531-336338313031\\/54674509.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1012300,
    url: "http://reby.ru/products/tproduct/522893231-540218462851-dvigatel-man-d2066lf42-euro5",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "JaUn1GdlVzFrPj96AYHc",
    editions: [
      {
        uid: 540218462851,
        price: "350000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/stor3930-6265-4935-b531-336338313031/54674509.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 587934557481,
    title: "Двигатель MAN D2066LF06 EURO2",
    sku: "",
    text: "На нашем сайте вы можете купить MAN D2066LF06 EURO2 двигатель комплектный в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "650000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/stor3763-6139-4830-b630-363433393136\\/17813929.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1012400,
    url: "http://reby.ru/products/tproduct/522893231-587934557481-dvigatel-man-d2066lf06-euro2",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[128606433521]",
    externalid: "g1RHXH8UyTYxtJ7HiUER",
    editions: [
      {
        uid: 587934557481,
        price: "650000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/stor3763-6139-4830-b630-363433393136/17813929.jpg",
      },
    ],
    characteristics: [],
  },
];
export default formattedArray(obj);
