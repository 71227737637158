import { useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import OrderPaymentsOption from "@/components/pages/OrderTrack/OrderPayment/OrderPaymentsLRadio/OrderPaymentsOption";
const isDisabled = (payWay, amount) => {
  const minDeposit = parseFloat(payWay?.info?.min_deposit);
  const maxDeposit = parseFloat(payWay?.info?.max_deposit);
  const amountValue = parseFloat(amount);
  if (!isNaN(minDeposit) && !isNaN(maxDeposit) && !isNaN(amountValue)) {
    return minDeposit < amountValue && amountValue <= maxDeposit;
  }

  return false;
};
const OrderPaymentsRadio = ({ payment, selected, setSelected }) => {
  const paymentOptions = payment?.pay_ways.map((payWay) => ({
    payWay,
    isDisabled: !isDisabled(payWay, payment?.amount),
  }));
  paymentOptions.sort((a, b) => {
    if (a.payWay.type === "drop" && !a.isDisabled) return -1; // "drop" без isDisabled перед іншими "drop"
    if (b.payWay.type === "drop" && !b.isDisabled) return 1;
    return a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1;
  });

  useEffect(() => {
    setSelected(paymentOptions?.[0]?.payWay);
    // eslint-disable-next-line
  }, []);

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="mb-2 text-sm text-dark-500 inline-block">
        Выберите способ оплаты:
      </RadioGroup.Label>
      <div className="space-y-4">
        {paymentOptions.map(({ payWay, isDisabled }) => (
          <OrderPaymentsOption
            isDisabled={isDisabled}
            key={payWay.name}
            RadioGroup={RadioGroup}
            payWay={payWay}
          />
        ))}
      </div>
    </RadioGroup>
  );
};

export default OrderPaymentsRadio;
