import Container from "@/components/ui/Container/Container";
import Card from "@/components/ui/Card";

export default function OrderTrackItems({
  items = [],
  total_price_with_discount,
  total_price,
  difference,
  vat,
}) {
  return (
    <Container className="mb-20">
      <p className="mb-2">Товары</p>
      <Card as="section">
        <div className={"overflow-y-auto"}>
          <table className="border-collapse table-auto w-full text-sm">
            <thead>
              <tr>
                <th className="border-b border-dark-600 font-medium p-4 pl-8 pt-0 pb-3 text-dark-200 text-left">
                  Наименование
                </th>
                <th className="border-b border-dark-600 font-medium p-4 pt-0 pb-3 text-dark-200 text-left">
                  Количество
                </th>
                <th className="border-b border-dark-600 font-medium p-4 pt-0 pb-3 text-dark-200 text-left">
                  Скидка %
                </th>
                <th className="border-b border-dark-600 font-medium p-4 pt-0 pb-3 text-dark-200 text-left">
                  За единицу ₽
                </th>
                <th className="border-b border-dark-600 font-medium p-4 pr-8 pt-0 pb-3 text-dark-200 text-right">
                  Стоимось ₽
                </th>
              </tr>
            </thead>
            <tbody className="bg-dark-800">
              {items.map((item, index) => (
                <tr key={index}>
                  <td className="border-b border-dark-700 p-4 pl-8 text-dark-400">
                    {item.name}
                  </td>
                  <td className="border-b border-dark-700 p-4 text-dark-400">
                    {item.amount}
                  </td>
                  <td className="border-b border-dark-700 p-4 text-dark-400">
                    {item.discount}
                  </td>
                  <td className="border-b border-dark-700 p-4 text-dark-400">
                    {item.price}
                  </td>
                  <td className="border-b border-dark-700 p-4 pr-8 text-dark-400 text-right">
                    {item.total}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-end text-sm mt-5 space-y-2">
          <p>
            <strong>Итого:</strong> {total_price}₽
          </p>
          <p>
            <strong>Cкидка:</strong> {difference}₽
          </p>
          <p>
            <strong>Итого со скидкой:</strong> {total_price_with_discount}₽
          </p>
          <p>
            <strong>НДС:</strong> {vat}₽
          </p>{" "}
          <p>
            <strong>Итого с НДС:</strong> {total_price_with_discount}₽
          </p>
        </div>
      </Card>
    </Container>
  );
}
