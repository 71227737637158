import { useDataContext } from "@/context/DataContext";
// import WhatsApp from "@/components/common/WhatsApp";
import Container from "@/components/ui/Container/Container";
import ContactsMap from "@/components/pages/Contacts/ContactsMap";
import Form from "@/components/common/Form";

const Contacts = () => {
  const { data } = useDataContext();
  const contacts = (
    <Container>
      <div className="relative flex flex-col lg:flex-row items-center h-full z-2">
        <div className="lg:w-1/2 h-52 lg:h-0"></div>
        <div className="rounded-2xl bg-dark-800 border border-dark-700 p-6 lg:w-1/2">
          <div>
            <p className="text-xl font-medium leading-6 mb-5 text-dark-100 ">
              Информация для связи
            </p>
            <ul className="text-dark-300 space-y-4 flex flex-col">
              <li>Адрес: {data.address}</li>
              <li>
                Эл. почта:{" "}
                <a href={`mailto:${data.email}`} className="underline">
                  {data.email}
                </a>
              </li>
            </ul>
          </div>
          <Form />
          {/*{data.isRun || <Form />}*/}
          {/*<div className="mt-4">*/}
          {/*  Или напишите нам в WhatsApp*/}
          {/*  <WhatsApp type="cta" />*/}
          {/*</div>*/}
        </div>
      </div>
    </Container>
  );

  return data.mapGeometry ? (
    <ContactsMap mapGeometry={data.mapGeometry}>{contacts}</ContactsMap>
  ) : (
    contacts
  );
};
export default Contacts;
