import Container from "@/components/ui/Container/Container";
import adviceList from "./adviceList";
import "./ContractEngineAdvice.css";

const ContractEngineAdvice = () => {
  return (
    <section className="py-16">
      <Container>
        <h2 className="text-2xl font-bold mb-3">
          Как выбрать контрактный двигатель
        </h2>
        <p className="mb-10">
          Покупка двигателя б/у — ответственное мероприятие. Прежде чем купить
          контрактный двигатель в Москве, убедитесь в надежности поставщика. А
          теперь непосредственно о выборе двигателя:
        </p>
        <ul className="flex flex-wrap justify-between">
          {adviceList.map(({ icon, title, text }) => {
            return (
              <li
                key={title}
                className="adviceItem flex items-start lg:flex-row flex-col"
              >
                <span className="p-3 bg-primary-200 rounded-full inline-block mb-3 lg:mr-3 text-primary-700 shadow-lg shadow-primary-200/50">
                  {icon}
                </span>
                <div>
                  <strong> {title}</strong>
                  <p>{text}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </Container>
    </section>
  );
};
export default ContractEngineAdvice;
