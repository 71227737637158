import "./steps.css";
import StepItem from "./StepItem";
import Container from "@/components/ui/Container/Container";
import stepsList from "./stepsList";

const Steps = () => {
  return (
    <section className="py-20">
      <Container>
        <div className="rounded-xl px-10 py-14 bg-dark-800">
          <div className="lg:w-2/3 mx-auto text-center mb-10">
            <h4 className="text-3xl lg:text-4xl font-bold mb-5">
              Как сделать заказ на контрактный двигатель?
            </h4>
            <p className="text-dark-400">
              Пошаговая инструкция по заказу двигателя через наш сервис
            </p>
          </div>
          <div className="row relative">
            <hr className="absolute left-0 top-20 border-dark-700 w-full hidden lg:block" />
            {stepsList.map((step, index) => {
              const count = index + 1;
              return (
                <StepItem
                  key={count}
                  {...step}
                  count={count}
                  isLast={count === stepsList.length}
                />
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Steps;
