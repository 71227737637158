import Container from "@/components/ui/Container/Container";
import {
  ArrowRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { useOrderContext } from "@/context/OrderContext/OrderContext";

export default function OrderUpSale({ up_sales }) {
  const { payHandler } = useOrderContext();

  return (
    <Container className="">
      <section className="p-4 bg-accent-500 text-white rounded-b-lg flex flex-col lg:flex-row lg:items-center justify-between">
        <div className="flex space-x-2 lg:items-center mb-4 lg:mb-0 ">
          <ExclamationCircleIcon className="w-7 h-7 block stroke-2 flex-[0_0_auto]" />
          <p className="font-bold text-lg leading-none">
            {up_sales.description}
          </p>
        </div>
        <button
          onClick={() => payHandler(up_sales?.price ?? 0, true)}
          className="rounded-lg border border-white px-3 py-2 transition-colors duration-500  hover:bg-white hover:text-accent-600"
        >
          <span className="flex space-x-2 justify-center items-center ">
            <span>Оплатить</span> <ArrowRightIcon className="w-5 h-5" />{" "}
          </span>
        </button>
      </section>
    </Container>
  );
}
