import Card from "@/components/ui/Card";
import Container from "@/components/ui/Container/Container";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const SuccessPayment = ({ payment }) => {
  return (
    <Container className="py-4 lg:py-10">
      <Card className="w-full lg:w-2/4 mx-auto flex flex-col justify-center items-center py-10">
        <CheckBadgeIcon className={"h-28 w-28 text-green-500 mb-5"} />
        <small className={"text-dark-500"}>#{payment?.uuid}</small>

        <h1 className={"text-2xl"}>Оплата прошла успешно</h1>

        <div className="my-5 text-center">
          <small className="text-dark-400">Сумма платежа:</small>
          <p className={"text-4xl font-bold"}>
            {payment?.finally_amount?.toFixed(2)} ₽
          </p>
        </div>
        <Link
          className="w-1/2 shrink-0 mt-2 sm:w-auto tracking-wide transition-colors duration-500 shadow-lg shadow-primary-500/50 rounded-lg text-white bg-primary-500 hover:bg-primary-600 px-5 py-2"
          to={`/order/${payment?.order}`}
        >
          Перейти к заказу
        </Link>
      </Card>
    </Container>
  );
};
export default SuccessPayment;
