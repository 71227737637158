import { useDataContext } from "@/context/DataContext";
import Container from "@/components/ui/Container/Container";

export default function OrderTrackPaymentInfo({ lead }) {
  const { data } = useDataContext();
  return (
    <Container>
      <section className="py-10 flex flex-col lg:flex-row -mx-3">
        <div className="w-full lg:w-1/2 p-3">
          <p className="font-bold">Отправитель:</p>
          <div className="text-sm text-dark-400">
            <p>{data.company}</p>
            <p>{data.address}</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 p-3 lg:text-right">
          <p className="font-bold">Получатель:</p>
          <div className="text-sm text-dark-400">
            <p>
              {lead?.name} {lead?.surname}
            </p>
            <p>{lead?.phone_number}</p>
            <p>{lead?.address}</p>
          </div>
        </div>
      </section>
    </Container>
  );
}
