import { useRef, useState, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import DynamicButton from "@/components/ui/DynamicButton";

export default function CopyInput({ text }) {
  const inputRef = useRef(null);
  const [isShowing, setIsShowing] = useState(false);

  const handleCopy = async () => {
    try {
      const inputElement = inputRef.current;
      await navigator.clipboard.writeText(inputElement.value);
      toggleShow();
    } catch (err) {
      console.error("Unable to copy text: ", err);
    }
  };

  const toggleShow = () => {
    setIsShowing(true);
    const timeout = setTimeout(() => {
      setIsShowing(false);
      clearTimeout(timeout);
    }, 1000);
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        className="inline-block border-2 border-primary-500 bg-dark-700 w-full placeholder:text-dark-400 text-white outline-none focus:ring-2 focus:ring-primary-800 p-4 rounded-md"
        ref={inputRef}
        value={text}
        readOnly
      />
      <DynamicButton
        type="button"
        className="absolute right-2 p-2 top-1/2 -translate-y-1/2"
        onClick={handleCopy}
      >
        <DocumentDuplicateIcon className={"w-6 h-6"} />
      </DynamicButton>

      <Transition
        as={Fragment}
        show={isShowing}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <small
          onClick={toggleShow}
          className="text-white bg-primary-600 rounded-lg py-1 px-2 absolute right-14 top-1/2 -translate-y-1/2"
        >
          Скопировано
        </small>
      </Transition>
    </div>
  );
}
