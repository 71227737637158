import BenefitsItem from "./BenefitsItem";
import benefitsList from "./benefitsList";
// import WhatsAppWidget from "@/components/common/WhatsApp";
import Container from "@/components/ui/Container/Container";
import Form from "@/components/common/Form";
// import { useDataContext } from "@/context/DataContext";

const Benefits = () => {
  // const { data } = useDataContext();
  return (
    <section className="py-16 bg-gradient-to-tr from-dark-900 to-dark-950">
      <Container>
        <div className="row items-center">
          <div className="col-auto">
            <div className="p-5 bg-primary-600 rounded-lg shadow-2xl">
              <ul className="-my-2">
                {benefitsList.map((benefit, index) => {
                  return (
                    <BenefitsItem
                      key={index}
                      {...benefit}
                      isLast={index + 1 === benefitsList.length}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="w-full col lg:w-2/5 lg:basis-2/5">
            <div className="block mb-6 mt-16 lg:mt-0">
              <h3 className="text-3xl font-bold mb-4">Почему мы?</h3>
              <p className="mb-4 ">
                Все двигатели закупаются напрямую из ОАЭ и проходят на тест на
                работоспособность и соответствие европейским стандартам качества
              </p>
              <small>
                На нашем складе более 5 000 двигателей для всех марок
                автомобилейв наличии
              </small>
            </div>
            {/*{data.isRun ? (*/}
            {/*  <p className="font-normal my-4">*/}
            {/*    Напиши нам в WhatsApp для обработки заявки*/}
            {/*  </p>*/}
            {/*) : (*/}
            {/*  <>*/}
            <Form />
            {/*<small className="font-normal">*/}
            {/*  Или напишите нам в WhatsApp*/}
            {/*</small>*/}
            {/*  </>*/}
            {/*)}*/}
            {/*<WhatsAppWidget type="cta" />*/}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Benefits;
