const Promo = ({ text }) => {
  function getTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const options = { month: "long", day: "numeric" };
    return tomorrow.toLocaleDateString("ru-Ru", options);
  }

  return (
    <>
      {text ? <p className="text-xl">{text}</p> : null}
      <p className="text-accent-400">Акция действует до {getTomorrowDate()}</p>
    </>
  );
};
export default Promo;
