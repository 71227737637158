import { cn } from "@/utils";

const CardFormInput = ({
  label,
  error,
  children,
  className,
  ...otherProps
}) => {
  return (
    <label className="w-full">
      <div className={"flex justify-between items-center"}>
        <small
          className={cn(
            "mb-2 inline-block",
            { "text-dark-400": !error },
            { "text-rose-500": error },
          )}
        >
          {error ? error : label}
        </small>
        {/*{error && (*/}
        {/*  <small className="text-rose-500 mb-2 inline-block">{error}</small>*/}
        {/*)}*/}
      </div>

      <div className="relative mb-3">
        <input
          className={cn(
            "inline-block border-2 border-primary-500 bg-dark-700 w-full placeholder:text-dark-400",
            "text-white outline-none focus:ring-2 focus:ring-primary-800 p-4 rounded-md",
            { "border-rose-500": error },
            className,
          )}
          {...otherProps}
        />
        {children && children}
      </div>
    </label>
  );
};

export default CardFormInput;
