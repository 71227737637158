import Loader from "@/components/common/Loader";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import { useEffect, useState } from "react";

const ContactsMap = ({ mapGeometry, children }) => {
  const [mapIsLoad, setMapIsLoad] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const checkIsMobile = () => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    setIsMobile(mediaQuery.matches);
  };
  useEffect(() => {
    checkIsMobile();
  }, []);
  const [lt, lg] = mapGeometry;
  const geometry = !isMobile
    ? { center: [lt - 0.15, lg], zoom: 10 }
    : { center: [lt, lg + 0.05], zoom: 13 };
  return (
    <>
      {!mapIsLoad && <Loader />}
      <YMaps>
        <div
          className="w-full relative flex flex-col justify-center"
          style={{ minHeight: "calc(100vh - 4rem)" }}
        >
          <Map
            onLoad={() => setMapIsLoad(true)}
            className="w-full h-full top-0 left-0 absolute z-0"
            defaultState={geometry}
          >
            <Placemark geometry={mapGeometry} />
          </Map>
          <div className="w-full h-full top-0 left-0 absolute z-1"></div>

          {children}
        </div>
      </YMaps>
    </>
  );
};
export default ContactsMap;
