import { useDropzone } from "react-dropzone";
import { cn } from "@/utils";

function FileUpload({ files, setFiles, className }) {
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };
  let { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    multiple: false,
    onDrop,
  });

  const fileItems = files?.map((file) => (
    <p key={file.path}>
      {file.path} - {file.size} байтов
    </p>
  ));

  return (
    <section className={cn("text-sm", className)}>
      <div
        {...getRootProps({
          className: cn(
            "dropzone cursor-pointer px-4 py-10 border-2 border-dashed border-dark-600 rounded-xl w-full text-center",
            { "border-primary-500": files.length > 0 },
          ),
        })}
      >
        <input {...getInputProps()} />
        {files?.length <= 0 ? (
          <p>Перетащите квитанцию сюда или нажмите для выбора файла</p>
        ) : (
          fileItems
        )}
      </div>
    </section>
  );
}

export default FileUpload;
