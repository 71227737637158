import "./HomePage.css";
import Hero from "./Hero";
import ContractEngineAdvice from "./ContractEngineAdvice";
import BenefitsSection from "./BenefitsSection";
import HomeProducts from "./HomeProducts";
import Faq from "./Faq";
import Steps from "./Steps";
const HomePage = () => {
  return (
    <>
      <Hero />
      <ContractEngineAdvice />
      <BenefitsSection />
      <HomeProducts />
      <Steps />
      <Faq />
    </>
  );
};
export default HomePage;
