import DeliveryHero from "./DeliveryHero";

const Delivery = () => {
  return (
    <>
      <DeliveryHero />
    </>
  );
};
export default Delivery;
