import FileUpload from "@/components/ui/FileUpload";
import DynamicButton from "@/components/ui/DynamicButton";
import { useCallback, useState } from "react";
import Http from "@/services/Http/Http.service";
import LoaderIcon from "@/components/common/Loader/LoaderIcon";

export default function PaymentConfirmation({ payment, setIsSend }) {
  const [files, setFiles] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const resetData = useCallback(() => {
    setFiles(() => []);
    setIsSending(false);
  }, [setFiles, setIsSending]);
  const sendFile = useCallback(() => {
    setIsSending(true);
    const url = `/confirm-payment.php`;
    const body = {
      amount: payment?.finally_amount,
      order: payment?.order,
      card: payment?.payment_system?.info?.card,
      uuid: payment?.uuid,
      contract_number: payment?.contract_number,
      is_upsale: payment?.is_upsale,
    };
    Http.uploadFile(url, files?.[0], body).then((response) => {
      if (response.status === 200) {
        setIsSend(true);
      }
      resetData();
    });
    // eslint-disable-next-line
  }, [files, payment]);
  console.log(files, isSending);
  return (
    <>
      <p className="py-10 text-center ">
        После оплаты прикрепите квитанцию об успешной оплате.
      </p>
      <FileUpload setFiles={setFiles} files={files} />
      <DynamicButton
        onClick={sendFile}
        type="button"
        disabled={files.length === 0 || isSending}
        className="w-full flex justify-center mt-5 px-6 py-4 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isSending ? (
          <LoaderIcon className="w-5 h-5 text-white/50 animate-spin fill-white" />
        ) : (
          "Отправить квитанцию"
        )}
      </DynamicButton>
    </>
  );
}
