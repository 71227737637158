import Modal from "@/components/ui/Modal";
import { useOrderContext } from "@/context/OrderContext/OrderContext";
import { useEffect, useState } from "react";

import OrderPaymentsRadio from "@/components/pages/OrderTrack/OrderPayment/OrderPaymentsLRadio";
import DynamicButton from "@/components/ui/DynamicButton";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import Http from "@/services/Http/Http.service";
import LoaderIcon from "@/components/common/Loader/LoaderIcon";
import { useNavigate } from "react-router-dom";

export default function OrderPayment() {
  const {
    payment: [payment],
    setError,
  } = useOrderContext();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedPayWay, setSelectedPayWay] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const navigateToPayment = (uuid) => {
    navigate(`/payment/${uuid}`);
  };
  const processPayment = (status, data) => {
    if (status === 201) {
      setIsSubmitting(false);
      navigateToPayment(data?.uuid);
    } else {
      setError((prev) => [...prev, "Что-то пошло не так попробуйте позже"]);
      setIsSubmitting(false);
      setIsOpen(false);
    }
  };
  const createPayment = async () => {
    setIsSubmitting(true);
    const url = `/create-payment.php`;
    const response = await Http.post(url, {
      ...payment,
      payment_system: selectedPayWay?.uuid,
    });
    processPayment(response?.status, response?.data);
  };

  useEffect(() => {
    setIsOpen(Object.keys(payment).length !== 0);
  }, [payment]);
  return (
    payment?.pay_ways && (
      <Modal
        modalTitle={
          <>
            <span className="mb-2 text-dark-500 text-sm">Сумма к оплате:</span>
            <p className="text-2xl">
              {parseFloat(payment?.amount).toFixed(2)} ₽
            </p>
          </>
        }
        closeHandler={() => setIsOpen((prevState) => !prevState)}
        isOpen={isOpen}
      >
        <OrderPaymentsRadio
          payment={payment}
          selected={selectedPayWay}
          setSelected={setSelectedPayWay}
        />
        <DynamicButton
          type="button"
          onClick={createPayment}
          className={
            "w-full mt-6 py-4 disabled:opacity-50 disabled:cursor-not-allowed"
          }
          disabled={isSubmitting}
        >
          <span className="flex space-x-2 justify-center items-center">
            {isSubmitting ? (
              <>
                <LoaderIcon className="w-5 h-5 text-white/50 animate-spin fill-white" />
                <span>Формируется платеж</span>
              </>
            ) : (
              <>
                <span>Оплатить {parseFloat(payment?.amount).toFixed(2)} ₽</span>
                <ArrowRightIcon className="w-5 h-5" />
              </>
            )}
          </span>
        </DynamicButton>
      </Modal>
    )
  );
}
