import { useState } from "react";
import Container from "@/components/ui/Container/Container";
import Card from "@/components/ui/Card";
import CopyInput from "@/components/ui/CopyInput";
import { formatCreditCardNumber } from "@/utils";
import PaymentConfirmation from "@/components/pages/Payment/PaymentConfirmation";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const DropPayment = ({ payment }) => {
  const [isSend, setIsSend] = useState(false);
  return (
    <Container className="py-4 lg:py-10">
      <Card className="w-full lg:w-2/4 mx-auto">
        <header className="pb-3 mb-6 border-b border-dark-700">
          <small className={"text-dark-500"}>#{payment?.uuid}</small>
          <h1 className="text-2xl font-bold">
            {payment?.payment_system?.ru_name}
          </h1>
          <p>{payment?.payment_system?.description}</p>
        </header>
        <div className="mb-5">
          <small className="text-dark-400">Сумма к оплате:</small>
          <p className={"text-4xl font-bold"}>
            {payment?.finally_amount?.toFixed(2)} ₽
          </p>
        </div>
        <p className="mb-2">
          Для оплаты по счету Вам необходимо сделать перевод на реквизиты
          финансового директора, которые указаны ниже.
        </p>
        <p className={"text-sm text-dark-300 mb-4"}>
          Данные реквизиты так же указаны в договоре, который заключается
          отдельно
        </p>
        <label className="w-full">
          <small className="text-dark-400 mb-2 inline-block">Номер карты</small>
          <CopyInput
            text={formatCreditCardNumber(payment?.payment_system?.info?.card)}
          />
        </label>
        <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-3 mt-3">
          <label className="w-full">
            <small className="text-dark-400 mb-2 inline-block">
              Срок дейстия
            </small>
            <CopyInput text={payment?.payment_system?.info?.expired} />
          </label>
          <label className="w-full">
            <small className="text-dark-400 mb-2 inline-block">
              ФИО бухгалтера
            </small>
            <CopyInput text={payment?.payment_system?.info?.full_name} />
          </label>
        </div>
        {payment?.contract_number && (
          <div className="mt-3">
            <small className="text-dark-400 mb-2 inline-block">
              Примечание
            </small>
            <CopyInput text={`Номер договора: ${payment?.contract_number}`} />
          </div>
        )}
        {!isSend ? (
          <PaymentConfirmation payment={payment} setIsSend={setIsSend} />
        ) : (
          <p
            className={"pt-16 pb-10 flex justify-center flex-col items-center"}
          >
            <CheckBadgeIcon className={"text-green-500 w-20 h-20"} />
            <p>Спасибо за оплату</p>
            <small>Наши сотрудники уже обрабативают оплату</small>
            <Link
              className="w-1/2 shrink-0 mt-5 sm:w-auto tracking-wide transition-colors duration-500 shadow-lg shadow-primary-500/50 rounded-lg text-white bg-primary-500 hover:bg-primary-600 px-5 py-2"
              to={`/order/${payment?.order}`}
            >
              Перейти к заказу
            </Link>
          </p>
        )}
      </Card>
      <div className={"flex justify-center items-center mt-4"}>
        <Link
          className="text-accent-500 hover:text-accent-600 hover:underline"
          to={`/order/${payment?.order}`}
        >
          Вернуться к заказу
        </Link>
      </div>
    </Container>
  );
};
export default DropPayment;
