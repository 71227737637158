import Benefits from "./Benefits";
import AboutHero from "./AboutHero";

const AboutUs = () => {
  return (
    <>
      <AboutHero />
      <Benefits />
    </>
  );
};
export default AboutUs;
