import { useState } from "react";
import ProductItem from "@/components/common/ProductsList/ProductItem";
import DynamicButton from "@/components/ui/DynamicButton";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import Modal from "@/components/ui/Modal";
import Form from "@/components/common/Form";

const ProductsList = ({ products, ...props }) => {
  const [limit, setLimit] = useState(20);
  const [showModal, setShowModal] = useState(false);

  const limitedProducts = products.slice(0, limit);
  return (
    <>
      <div className="mt-16 grid grid-cols-1 gap-5 sm:mt-20 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {limitedProducts.map((product) => {
          return (
            <ProductItem
              key={product.uid}
              product={product}
              setShowModal={setShowModal}
            />
          );
        })}
      </div>
      <div className="flex items-center justify-center mt-5">
        {limitedProducts.length < products.length && (
          <DynamicButton
            onClick={() => setLimit((prevState) => prevState + 10)}
            type="button"
            className="flex items-center justify-center gap-x-2 mt-5 px-6 py-4 "
          >
            Загрузить еще
            <ArrowPathIcon className="w-5 h-5" />
          </DynamicButton>
        )}
      </div>
      {showModal && (
        <Modal
          modalTitle={"Для заказа заполните форму"}
          isOpen={showModal}
          closeHandler={() => setShowModal(false)}
        >
          <Form />
        </Modal>
      )}
    </>
  );
};
export default ProductsList;
