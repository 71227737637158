import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import creditCardType, { types as CardType } from "credit-card-type";

function formatAndCleanObject(obj) {
  const { uid, title, price, text, editions } = obj;
  const firstEditionImg = editions.length > 0 ? editions[0].img : null;

  const gallery = JSON.parse(obj.gallery);

  return {
    uid,
    title,
    price,
    text,
    img: firstEditionImg,
    gallery: gallery.map((item) => item.img),
  };
}

export const timestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.getMonth() + 1;

  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedMonth = month.toString().padStart(2, "0");
  const formattedDay = day.toString().padStart(2, "0");

  return `${formattedDay}.${formattedMonth}.${year} ${hours}:${minutes}`;
};
// export function formatCreditCardNumber(cardNumber) {
//   cardNumber = cardNumber.replace(/\s/g, "");
//
//   return cardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");
// }
export function cn(...inputs) {
  return twMerge(classNames(inputs));
}
// const getImgName = (img) => {
//   const temp = img.split("/");
//   return temp[temp.length - 1];
// };

export function isUUIDv4(uuid) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}
const getImgName = (urlString) => {
  const parsedUrl = urlString.split("/");
  const filePath =
    parsedUrl[parsedUrl.length - 2] + parsedUrl[parsedUrl.length - 1];
  return filePath.replace("tild", "").replaceAll("/", "");
};

const getUrlParams = () => {
  return new URLSearchParams(window.location.search);
};

export const getParamsWithPrefix = (startWith) => {
  const urlParams = getUrlParams();
  const params = {};
  for (const [param, value] of urlParams) {
    if (param.startsWith(startWith)) {
      params[param] = value;
    }
  }
  return params;
};
export const setToLocalStorage = (key, data) => {
  if (!getFromLocalStorage(key)) {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

export const getFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

function updateObjectsArray(objectsArray) {
  const imageDirectory = "/static/products"; // Шлях до завантажених зображень на вашому сервері

  return objectsArray.map((obj) => {
    const { gallery } = obj;

    const updatedGallery = gallery.map((galleryImgURL) => {
      const galleryImgFileName = getImgName(galleryImgURL);
      return `${imageDirectory}/${galleryImgFileName}`;
    });

    const imgFileName = getImgName(obj.img);
    const updatedImg = `${imageDirectory}/${imgFileName}`;
    return {
      ...obj,
      img: updatedImg,
      gallery: updatedGallery,
    };
  });
}

export const formattedArray = (inputArray) => {
  return updateObjectsArray(inputArray.map(formatAndCleanObject));
};

export function validateCreditCard(cardNumber) {
  cardNumber = cardNumber.replace(/\D/g, "");

  if (
    cardNumber.length !== 13 &&
    cardNumber.length !== 15 &&
    cardNumber.length !== 16
  ) {
    return false;
  }

  let sum = 0;
  let doubleUp = false;

  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);

    if (doubleUp) {
      digit *= 2;

      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;

    doubleUp = !doubleUp;
  }

  return sum % 10 === 0;
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export const getCardType = (number) => {
  const cardType = creditCardType(number).filter(function (card) {
    return (
      card.type === CardType.MASTERCARD ||
      card.type === CardType.VISA ||
      card.type === CardType.MIR ||
      card.type === CardType.UNIONPAY
    );
  });
  return cardType[0];
};
export function formatCreditCardNumber(input, cardInfo) {
  cardInfo = cardInfo || getCardType(input);

  if (!cardInfo) {
    return input;
  }
  const cardNumber = input.replace(/\D/g, "");

  const gaps = cardInfo.gaps;
  const maxLength = cardInfo.lengths[cardInfo.lengths.length - 1];

  let formattedCardNumber = "";

  for (let i = 0; i < cardNumber.length; i++) {
    if (gaps.includes(i) && i < maxLength - 1) {
      formattedCardNumber += " ";
    }
    if (i < maxLength) {
      formattedCardNumber += cardNumber[i];
    } else {
      break;
    }
  }

  return formattedCardNumber;
}

export function formatCVC(value, maxLength = 4) {
  const clearValue = clearNumber(value);
  return clearValue.slice(0, maxLength);
}
export function validateExpiryDate(expiry) {
  const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;

  if (!regex.test(expiry)) {
    return false;
  }

  const [month, year] = expiry.split("/");

  const currentYear = new Date().getFullYear() % 100;
  const currentMonth = new Date().getMonth() + 1;

  if (
    Number(year) < currentYear ||
    (Number(year) === currentYear && Number(month) < currentMonth)
  ) {
    return false;
  }

  return true;
}
export function formatMonthWithLeadingZero() {
  const currentMonth = new Date().getMonth() + 1; // +1, оскільки getMonth повертає місяці з 0 (січень) до 11 (грудень)
  return currentMonth < 10 ? `0${currentMonth}` : String(currentMonth);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}
