import { formattedArray } from "@/utils";

const obj = [
  {
    uid: 721193641591,
    title: "МКПП Peugeot Boxer/ Citroen Jumper 2.2 HDI",
    sku: "",
    text: "120 л.с., шестиступенчатая 2006-2012",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "55000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6334-6164-4433-b762-303137326339\\/__Peugeot_Boxer__Cit.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1005200,
    url: "http://reby.ru/products/tproduct/522893197-721193641591-mkpp-peugeot-boxer-citroen-jumper-22-hdi",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "RKhXrfC2U52y9ybN1F4j",
    editions: [
      {
        uid: 721193641591,
        price: "55000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6334-6164-4433-b762-303137326339/__Peugeot_Boxer__Cit.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 112866399351,
    title: "МКПП Opel Movano 2.5 TDCI",
    sku: "",
    text: "",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "30000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6666-3162-4135-b936-366334336334\\/__Opel_Movano_25TDCI.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1005300,
    url: "http://reby.ru/products/tproduct/522893197-112866399351-mkpp-opel-movano-25-tdci",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "7mZp2K0Udu8PHdE5JFYC",
    editions: [
      {
        uid: 112866399351,
        price: "30000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6666-3162-4135-b936-366334336334/__Opel_Movano_25TDCI.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 381969369841,
    title: "МКПП RJ F7 / Mazda 5 (CR) 2.0 CD",
    sku: "",
    text: "RJ F7 7TF0912536. 2005-2010 год.",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "47000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3339-3931-4764-b264-363136316332\\/_RJF7__RJ_F7_7TF0912.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1005400,
    url: "http://reby.ru/products/tproduct/522893197-381969369841-mkpp-rj-f7-mazda-5-cr-20-cd",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "x7afDebzRYo76A01L2B0",
    editions: [
      {
        uid: 381969369841,
        price: "47000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3339-3931-4764-b264-363136316332/_RJF7__RJ_F7_7TF0912.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 847270125621,
    title: "МКПП DQL Volkswagen",
    sku: "",
    text: "",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "35000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3165-3034-4163-b032-663735373231\\/_DQL_Volkswagen__350.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1005500,
    url: "http://reby.ru/products/tproduct/522893197-847270125621-mkpp-dql-volkswagen",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "FMlIgRgA9GnIu3ZZR5CH",
    editions: [
      {
        uid: 847270125621,
        price: "35000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3165-3034-4163-b032-663735373231/_DQL_Volkswagen__350.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 811644875071,
    title: "АКПП типтроник ZF 5HP-24",
    sku: "",
    text: "Для Audi A8,A6,S8,S6. 4.2 л. Quattro. Полный привод",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "55000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3338-6463-4637-b433-383339323731\\/__ZF_5HP-24__Audi_A8.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1005600,
    url: "http://reby.ru/products/tproduct/522893197-811644875071-akpp-tiptronik-zf-5hp-24",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "0oOp8o4bS6DOsSK5hh5k",
    editions: [
      {
        uid: 811644875071,
        price: "55000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3338-6463-4637-b433-383339323731/__ZF_5HP-24__Audi_A8.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 425089881271,
    title: "АКПП Toyota Highlander I 3.0",
    sku: "",
    text: "V6 24V 1MZ-FE 4WD 2001-2006 год",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "50000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6634-6236-4238-b730-643062636264\\/_Toyota_Highlander_I.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1005700,
    url: "http://reby.ru/products/tproduct/522893197-425089881271-akpp-toyota-highlander-i-30",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "MAb8OkAecxeDI0fZXzlI",
    editions: [
      {
        uid: 425089881271,
        price: "50000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6634-6236-4238-b730-643062636264/_Toyota_Highlander_I.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 670816240071,
    title: "АКПП GM 96497031",
    sku: "",
    text: "Для Chevrolet Lacetti, Daewoo Nubira, Chevrolet Opta. 1.6 л",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "30000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6138-3530-4263-a437-326230663163\\/_GM_96497031__Chevro.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1005800,
    url: "http://reby.ru/products/tproduct/522893197-670816240071-akpp-gm-96497031",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "PG7SJe7TRPhECC4RyTtI",
    editions: [
      {
        uid: 670816240071,
        price: "30000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6138-3530-4263-a437-326230663163/_GM_96497031__Chevro.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 873190320701,
    title: "АКПП Aisin 55-51SN",
    sku: "",
    text: "Volvo XC90, Volvo XC70, Volvo S80. 2.5T бензин. 2005-2007 г.",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "45000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6361-3533-4432-a131-323161363666\\/_Aisin_55-51SN__Volv.PNG"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1005900,
    url: "http://reby.ru/products/tproduct/522893197-873190320701-akpp-aisin-55-51sn",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "OO6UdSH1uFB518aTfO8v",
    editions: [
      {
        uid: 873190320701,
        price: "45000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6361-3533-4432-a131-323161363666/_Aisin_55-51SN__Volv.PNG",
      },
    ],
    characteristics: [],
  },
  {
    uid: 677221540361,
    title: "АКПП Lancer1.8",
    sku: "",
    text: "На нашем сайте вы можете купить АКПП Lancer1.8 в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "58000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3935-3037-4238-b962-313765373463\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1009300,
    url: "http://reby.ru/products/tproduct/522893197-677221540361-akpp-lancer18",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "birEiPoiBHrTdELZmxAm",
    editions: [
      {
        uid: 677221540361,
        price: "58000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3935-3037-4238-b962-313765373463/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 547761952481,
    title: "АКПП Jaguar xf",
    sku: "",
    text: "На нашем сайте вы можете купить АКПП Jaguar xf в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "50000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6637-6338-4565-b236-343638386532\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1009400,
    url: "http://reby.ru/products/tproduct/522893197-547761952481-akpp-jaguar-xf",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "BFAyDHjtnYimBMnIfdu2",
    editions: [
      {
        uid: 547761952481,
        price: "50000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6637-6338-4565-b236-343638386532/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 260485431821,
    title: "МКПП Opel astra g 1.8",
    sku: "",
    text: "На нашем сайте вы можете купить МКПП Opel astra g 1.8 в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "30000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild6232-3530-4266-b731-333837653736\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1009500,
    url: "http://reby.ru/products/tproduct/522893197-260485431821-mkpp-opel-astra-g-18",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "gTXj5pH3ALEj6KDJnLKu",
    editions: [
      {
        uid: 260485431821,
        price: "30000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild6232-3530-4266-b731-333837653736/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 873459047441,
    title: "МКПП Kia cerato 2.0 g4gc",
    sku: "",
    text: "На нашем сайте вы можете купить МКПП Kia cerato 2.0 g4gc в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "36000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3037-6135-4534-b066-363036313335\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1009600,
    url: "http://reby.ru/products/tproduct/522893197-873459047441-mkpp-kia-cerato-20-g4gc",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "vYXiRoLxsosaAIYLH5ec",
    editions: [
      {
        uid: 873459047441,
        price: "36000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3037-6135-4534-b066-363036313335/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
  {
    uid: 658722650911,
    title: "АКПП Ssangyong actyon sport 2.2",
    sku: "",
    text: "На нашем сайте вы можете купить&nbsp;АКПП Ssangyong actyon sport 2.2 в почти в новом состоянии и по низкой цене. ",
    mark: "",
    quantity: "",
    portion: 0,
    unit: "",
    single: "",
    price: "115000.0000",
    priceold: "",
    descr: "",
    gallery:
      '[{"img":"https:\\/\\/static.tildacdn.com\\/tild3962-3437-4231-a162-333961616465\\/lexus-rx-450-h-450h-.jpg"}]',
    buttonlink: "",
    buttontarget: "",
    json_options: "",
    sort: 1009700,
    url: "http://reby.ru/products/tproduct/522893197-658722650911-akpp-ssangyong-actyon-sport-22",
    pack_label: "lwh",
    pack_x: 0,
    pack_y: 0,
    pack_z: 0,
    pack_m: 0,
    partuids: "[753162012001]",
    externalid: "RscAKzyd8bfTo4d0XfSn",
    editions: [
      {
        uid: 658722650911,
        price: "115000.0000",
        priceold: "",
        sku: "",
        quantity: "",
        img: "https://static.tildacdn.com/tild3962-3437-4231-a162-333961616465/lexus-rx-450-h-450h-.jpg",
      },
    ],
    characteristics: [],
  },
];
export default formattedArray(obj);
