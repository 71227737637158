import React, { createContext, useContext } from "react";
import useLocalStorage from "@/hooks/useLocalStorage";

const DataContext = createContext();

export function useDataContext() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const [data, setData] = useLocalStorage("siteData", null);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
}
