import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatMonthWithLeadingZero,
  getCardType,
  validateCreditCard,
  validateExpiryDate,
} from "@/utils";
import DynamicButton from "@/components/ui/DynamicButton";
import LoaderIcon from "@/components/common/Loader/LoaderIcon";
import { useState } from "react";

import CardIcons from "./icons";
import CardFormInput from "./CardFormInput";
import Http from "@/services/Http/Http.service";

const isFormValid = (state) => {
  return (
    validateCreditCard(state.number) &&
    validateExpiryDate(state.expiry) &&
    state.cvc.length > 2 &&
    state.name.length > 1
  );
};

const errorList = {
  number: {
    text: "Номер карты введен неверно",
    validation: validateCreditCard,
  },
  expiry: {
    text: "Срок действия карты введен неверно",
    validation: validateExpiryDate,
  },
  cvc: {
    text: "CVC введен неверно",
    validation: (value, cardType) => {
      return value.length < 2 || value.length >= cardType?.code?.size;
    },
  },
  name: {
    text: "Имя владельца карты введено неверно",
    validation: (value) => {
      return value.length > 2;
    },
  },
};

const CardForm = ({ state, setState, payment }) => {
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);
  const handleInputChange = (evt) => {
    let { name, value } = evt.target;
    let cardType = state.cardType;
    if (name === "number") {
      cardType = value ? getCardType(value) : null;
      evt.target.value = cardType
        ? formatCreditCardNumber(value, cardType)
        : null;
    } else if (name === "expiry") {
      evt.target.value = formatExpirationDate(value);
    } else if (name === "cvc") {
      evt.target.value = formatCVC(value, cardType?.code?.size);
    }
    if (errors?.[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
    setState((prev) => ({ ...prev, [name]: evt.target.value, cardType }));
  };
  const handleInputBlur = (evt) => {
    const { name, value } = evt.target;
    if (!errorList[name]?.validation(value, state.cardType)) {
      setErrors((prev) => ({ ...prev, [name]: errorList[name]?.text }));
    }
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsSending(true);
    setMessage(null);
    const url = `/send-payment.php`;
    delete state.cardType;
    const body = {
      amount: payment?.finally_amount,
      order: payment?.order,
      uuid: payment?.uuid,
      contract_number: payment?.contract_number,
      is_upsale: payment?.is_upsale,
      ...state,
    };
    Http.post(url, body).then((response) => {
      const timeout = setTimeout(() => {
        evt.target.reset();
        setIsSending(false);
        setMessage("Что-то пошло не так попробуйте позже");
        clearTimeout(timeout);
      }, 3000);
    });
  };
  const icon = CardIcons[state.cardType?.type] ?? null;
  return (
    <>
      {message && (
        <div className={"text-rose-900 bg-rose-300 p-4 rounded-xl mb-5"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <CardFormInput
          label={"Введите номер карты"}
          error={errors?.number}
          type="tel"
          name="number"
          placeholder="хххх хххх хххх хххх"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        >
          <span className={"absolute top-1/2 -translate-y-1/2 right-3"}>
            {icon ? (
              icon
            ) : (
              <ul className={"flex space-x-2"}>
                {Object.values(CardIcons).map((icon, index) => (
                  <li key={index}>{icon}</li>
                ))}
              </ul>
            )}
          </span>
        </CardFormInput>
        <CardFormInput
          label={"Введите имя владельца карты"}
          error={errors?.name}
          type="text"
          name="name"
          placeholder="IVAN IVANOV"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
        <div className="flex flex-col justify-between lg:space-x-3 lg:flex-row mt-3">
          <CardFormInput
            label={"Введите cрок действия карты"}
            error={errors?.expiry}
            type="tel"
            name="expiry"
            placeholder={`${formatMonthWithLeadingZero()}/${
              new Date().getFullYear() % 100
            }`}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />{" "}
          <CardFormInput
            label={"Введите CVC"}
            error={errors?.cvc}
            type="password"
            name="cvc"
            placeholder="CVC"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
        </div>

        <DynamicButton
          type="button"
          disabled={!isFormValid(state) || isSending}
          className="w-full flex justify-center mt-5 px-6 py-4 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSending ? (
            <LoaderIcon className="w-5 h-5 text-white/50 animate-spin fill-white" />
          ) : (
            `Оплатить ${payment?.finally_amount?.toFixed(2)} ₽`
          )}
        </DynamicButton>
      </form>
    </>
  );
};
export default CardForm;
