import LoaderIcon from "@/components/common/Loader/LoaderIcon";

const Loader = ({ height = "h-screen" }) => {
  return (
    <div
      id="loader"
      className={`z-[999999999] bg-dark-800 fixed top-0 left-0 w-full flex items-center justify-center ${height}`}
    >
      <LoaderIcon className={"w-20 h-20 "} />
    </div>
  );
};

export default Loader;
