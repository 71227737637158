import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class HttpService {
  $http = axios.create();

  async get(path) {
    try {
      return await this.$http.get(`${BASE_URL}${path}`);
    } catch (error) {
      return error.response;
    }
  }

  async post(path, body) {
    try {
      return await this.$http.post(`${BASE_URL}${path}`, body);
    } catch (error) {
      return error.response;
    }
  }
  async uploadFile(path, file, body = {}) {
    try {
      const formData = new FormData();
      formData.append("file", file);

      // Append additional data to the FormData
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          formData.append(key, body[key]);
        }
      }

      return await this.$http.post(`${BASE_URL}${path}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      return error.response;
    }
  }
}
const index = new HttpService();
export default index;
