const Container = ({ children, className }) => {
  return (
    <div
      className={`container mx-auto px-4 lg:px-6 ${className ? className : ""}`}
    >
      {children}
    </div>
  );
};

export default Container;
