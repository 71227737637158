const benefitsList = [
  {
    title: "Гарантия на двигатель до 60 дней",
    text:
      "Мы несем отвественность за работоспособность наших двигателей, " +
      "случае неисправности производим 100% возврат денег и замену запчасти",
  },
  {
    title: "Маленький пробег",
    text: "У нас вы можете заказать контрактный двигатель с небольшим пробегом",
  },
  {
    title: "Низкие цены",
    text: "Благодаря прямым поставкам из ОАЭ, мы можем предложить вам лучшее соотношение цена-качество",
  },
  {
    title: "Быстрая доставка по всей России",
    text: "Мы в кратчайший сроки доставим двигатель в любой город России",
  },
  {
    title: "Обратная связь",
    text: "Мы работаем 7 дней в неделю, можете связаться с нами любым удобным для Вас способом",
  },
];
export default benefitsList;
