import { useOrderContext } from "@/context/OrderContext/OrderContext";
import { cn } from "@/utils";
import {
  ArchiveBoxArrowDownIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  Cog6ToothIcon,
  DocumentCheckIcon,
  TruckIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Container from "@/components/ui/Container/Container";
import Card from "@/components/ui/Card";

const icons = {
  created: Cog6ToothIcon,
  pending: CheckBadgeIcon,
  paid: BanknotesIcon,
  preparingForCustoms: ClipboardDocumentCheckIcon,
  inTransitToCustoms: TruckIcon,
  clearedCustomsAndEnRoute: DocumentCheckIcon,
  readyForPickup: ArchiveBoxArrowDownIcon,
};
const setIsComplete = (order, status) => {
  return order
    ? order?.statuses
        .slice(
          0,
          order?.statuses.findIndex((s) => s?.status === order?.status) + 1,
        )
        .includes(status)
    : false;
};
export default function OrderTrackStatuses() {
  const {
    order: [order],
  } = useOrderContext();
  const statuses = order?.statuses.filter(
    (status) => status?.status !== "rejected",
  );
  const rejectedStatus = order?.statuses.find(
    (status) => status?.status === "rejected",
  );
  return (
    <Container>
      <p className="mb-2">Статус заказа</p>
      <Card as={"section"}>
        <ol className="flex flex-wrap flex-col lg:flex-row text-gray-500 w-full  relative ">
          {order?.status !== "rejected" ? (
            statuses.map((status, index) => {
              const Icon = icons[status?.status] ?? function () {};
              const isComplete = setIsComplete(order, status);
              return (
                <li
                  key={status?.status}
                  className={cn(
                    "flex relative p-3 w-full lg:w-1/4",
                    "before:border-dark-700 before:border-l before:absolute before:h-full before:top-0 before:left-6 z-0 ",
                    "lg:before:hidden",
                    {
                      "text-white": isComplete,
                    },
                  )}
                >
                  <span
                    className={cn(
                      `flex items-center relative z-1 justify-center w-6 h-6  shrink-0 rounded-full  ring-4 ring-gray-900 bg-gray-700 mr-5`,
                      { "bg-green-900": isComplete },
                    )}
                  >
                    <Icon
                      key={index}
                      className={cn(
                        "w-4 h-4",
                        { "text-gray-400": !isComplete },
                        { "text-green-400": isComplete },
                      )}
                    />
                  </span>
                  <div>
                    <h3 className="font-medium leading-tight">
                      {status?.title}
                    </h3>
                    <p className="text-sm">{status?.description}</p>
                  </div>
                </li>
              );
            })
          ) : (
            <li className={cn("flex relative p-3 w-full text-white")}>
              <span
                className={cn(
                  `flex bg-red-900 items-center relative z-1 justify-center w-6 h-6  shrink-0 rounded-full  ring-4 ring-gray-900 mr-5`,
                )}
              >
                <XCircleIcon className={cn("w-4 h-4 text-red-400")} />
              </span>
              <div>
                <h3 className="font-medium leading-tight">
                  {rejectedStatus?.title}
                </h3>
                <p className="text-sm">{rejectedStatus?.description}</p>
              </div>
            </li>
          )}
        </ol>
      </Card>
    </Container>
  );
}
