import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_SK;
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
function getStorageValue(key, defaultValue) {
  const saved = localStorage.getItem(key);
  if (saved) {
    const initial = JSON.parse(decryptData(saved));
    return initial || defaultValue;
  }

  return saved;
}
const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, encryptData(JSON.stringify(value)));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
