export const navigation = [
  { name: "Главная", href: "/" },
  { name: "О нас", href: "/about-us" },
  { name: "Ассортимент", href: "/products" },
  { name: "Условия доставки", href: "/delivery" },
  { name: "Контакты", href: "/contacts" },
  { name: "Отследить заказ", href: "/order" },
];
const router = {};
export default router;
