const BenefitsItem = ({ title, text, isLast }) => {
  return (
    <li
      className={`py-2 px-1 ${
        !isLast ? "border-b border-dashed border-primary-200/50" : null
      }`}
    >
      <p className="font-bold text-xl mb-2">{title}</p>
      <p className="text-primary-200">{text}</p>
    </li>
  );
};

export default BenefitsItem;
