import React, { useEffect } from "react";

export default function ErrorList({ errors, setError }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      setError((prev) => prev.slice(1));
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  const removeError = (index) => {
    setError((prev) => prev.filter((_, i) => i !== index));
  };
  return (
    errors.length > 0 && (
      <div className="fixed top-5 z-[99999999] w-full lg:w-1/4 flex flex-col space-y-2 px-3  left-1/2 -translate-x-1/2">
        {errors.map((error, index) => {
          return (
            <div
              onClick={() => removeError(index)}
              key={index}
              className="cursor-pointer bg-rose-300 rounded-lg text-rose-900 p-4 shadow-lg shadow-rose-500/40"
            >
              {error}
            </div>
          );
        })}
      </div>
    )
  );
}
