import { Link } from "react-router-dom";
import { Fragment } from "react";
import "./DynamicButton.css";
import { cn } from "@/utils";
const buttonsCollection = {
  a: ({ to, children, ...props }) => {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  },
  button: ({ children, ...props }) => {
    return <button {...props}>{children}</button>;
  },
  link: ({ to, children, ...props }) => {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  },
};

const DynamicButton = ({ type, className, ...props }) => {
  const Component = buttonsCollection[type] ?? Fragment;
  return (
    <Component
      className={cn("btn inline-block px-5 py-3", className)}
      {...props}
    />
  );
};

export default DynamicButton;
