import { useEffect, useId, useRef } from "react";
import "./faq-item.css";
import FaqIcon from "./FaqIcon";
import FaqRotateIcon from "./FaqRotateIcon";
const FaqItem = ({
  title,
  text,
  isFirst,
  isLast,
  isOpen,
  onClickHandler,
  itemsRef,
}) => {
  const itemRef = useRef();
  const accordionId = useId();

  useEffect(() => {
    itemsRef.current.push(itemRef.current);
  }, [itemsRef, itemRef]);

  return (
    <>
      <p id={`accordion-open-heading-${accordionId}`}>
        <button
          onClick={onClickHandler}
          type="button"
          className={`accordion-btn 
            ${isFirst ? "rounded-t-xl" : ""} ${!isLast ? "!border-b-0" : ""} 
            ${isOpen ? "active" : ""}`}
          data-accordion-target={`accordion-open-body-${accordionId}`}
          aria-expanded={isOpen}
          aria-controls={`accordion-open-body-${accordionId}`}
        >
          <span className="flex items-center">
            <FaqIcon />
            {title}
          </span>
          <FaqRotateIcon isOpen={isOpen} />
        </button>
      </p>
      <div
        ref={itemRef}
        id={`accordion-open-body-${accordionId}`}
        aria-labelledby={`accordion-open-heading-${accordionId}`}
        className={`accordion-panel ${!isOpen ? "max-h-0" : ""}`}
      >
        <div
          className={`p-5 border
           ${isLast ? "rounded-b-xl" : "border-b-0"}
           border-dark-700 bg-dark-900`}
        >
          <p className="text-gray-400">{text}</p>
        </div>
      </div>
    </>
  );
};
export default FaqItem;
