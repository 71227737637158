import bg from "./bg.jpeg";
import Container from "@/components/ui/Container/Container";
import {
  ArchiveBoxIcon,
  CubeTransparentIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
const stats = [
  {
    name: "Запчасти изолированы друг от друга.",
    icon: <CubeTransparentIcon className="w-10 h-10" />,
  },
  {
    name: "Всегда заказывается жесткая упаковка (деревянный ящик)",
    icon: <ArchiveBoxIcon className="w-10 h-10" />,
  },
  {
    name: "Предусмотрены правила размещения запчастей в контейнере в целях их сохранности.",
    icon: <ShieldCheckIcon className="w-10 h-10" />,
  },
];
const DeliveryHero = () => {
  return (
    <section className="py-16 relative">
      <img
        src={bg}
        alt=""
        className="absolute inset-0 z-0 h-full w-full object-cover object-right md:object-center"
      />
      <div className="object-cover absolute object-center w-full h-full top-0 left-0 bg-dark-900/75 z-1"></div>
      <Container>
        <div className="relative isolate overflow-hidden py-6 lg:py-24 px-6">
          <div
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-accent-500 to-primary-500 opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-accent-500 to-primary-500 opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div>
            <div>
              <h2 className="text-4xl font-bold tracking-tight mb-4 text-white sm:text-6xl">
                Условия доставки
              </h2>
              <p className="mt-6 text-gray-300">
                Максимально все просто. Заявка в транспортную компанию, отвоз
                груза, присутствие при упаковке, фотофиксация, контроль
                перевозки и получения, связь с клиентом по факту получения.
                Здесь нет ничего сложного, схема отработана и проста.
              </p>
            </div>

            <div className="mt-10">
              <p className="mt-16 sm:mt-20 mb-10 text-xl font-bold">
                Как мы защищаем наши товары при перевозках
              </p>

              <dl className=" grid grid-cols-1 gap-8  lg:grid-cols-3">
                {stats.map((stat) => (
                  <div key={stat.name} className="flex flex-col items-start">
                    <dd className="p-3 bg-primary-200 rounded-full inline-block mb-3 lg:mr-3 text-primary-700 shadow-lg shadow-primary-200/50">
                      {stat.icon}
                    </dd>
                    <dt className="text-base leading-7 text-gray-300">
                      {stat.name}
                    </dt>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DeliveryHero;
