import { Outlet } from "react-router-dom";
import { OrderProvider } from "@/context/OrderContext/OrderContext";

const OrderLayout = () => {
  return (
    <OrderProvider>
      <Outlet />
    </OrderProvider>
  );
};
export default OrderLayout;
