import NavBar from "@/components/layout/NavBar";
import { Outlet } from "react-router-dom";
// import WhatsApp from "@/components/common/WhatsApp";
import Footer from "@/components/layout/Footer";

const Layout = () => {
  return (
    <>
      <NavBar />
      <div className="pt-16">
        <Outlet />
      </div>
      {/*<WhatsApp type="fixed" />*/}

      <Footer />
    </>
  );
};
export default Layout;
