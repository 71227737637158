const fakeObject = {
  uuid: "63d0c846-3e06-4fac-aefe-79be2e",
  lead: {
    name: "Иван",
    phone_number: "+7 (999) 999-99-99",
    surname: "Иванов",
    address: "Москва, ул. Ленина, д. 1, кв. 1",
  },
  items: [
    {
      amount: 10,
      price: 100.0,
      total: 1000.0,
      name: "Мотор toyota 1.8",
      discount: 10.0,
    },
    {
      amount: 10,
      price: 100.0,
      total: 1000.0,
      name: "АКПП toyota 1.8",
      discount: 10.0,
    },
  ],
  total_price: 1000.0,
  total_price_with_discount: 900.0,
  difference: 100.0,
  created_at: "1694373218",
  updated_at: "1694373218",
  expired_at: "1694805209",
  status: "pending",
};
export default fakeObject;
